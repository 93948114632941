var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VAlert",
    {
      staticClass: "black--text ma-0",
      style: _vm.style,
      attrs: { color: _vm.backgroundColor },
      model: {
        value: _vm.alert,
        callback: function ($$v) {
          _vm.alert = $$v
        },
        expression: "alert",
      },
    },
    [
      _vm._t("default", function () {
        return [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }