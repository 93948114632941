var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "absolute", width: "0", height: "0" } },
    [
      _vm.showTermsOfService
        ? _c("TermsOfServiceModal", {
            attrs: {
              needsAcceptance: _vm.isPolicyUnaccepted(
                _vm.policies.TERMS_OF_SERVICE
              ),
            },
            on: {
              accept: _vm.handleAcceptPolicy,
              open: _vm.openPolicy,
              close: _vm.closePolicy,
            },
          })
        : _vm._e(),
      _vm.showPrivacyPolicy
        ? _c("PrivacyPolicyModal", {
            attrs: {
              needsAcceptance: _vm.isPolicyUnaccepted(_vm.policies.PRIVACY),
            },
            on: {
              accept: _vm.handleAcceptPolicy,
              open: _vm.openPolicy,
              close: _vm.closePolicy,
            },
          })
        : _vm._e(),
      _vm.showCommunityStandards
        ? _c("CommunityStandardsModal", {
            attrs: { needsAcceptance: false },
            on: { close: _vm.closePolicy },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }