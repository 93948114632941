var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        version: "1.1",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "m8 0a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8zm0 3.2734a3.308 3.308 0 0 1 3.3086 3.3086c0 1.1247-0.56263 2.1128-1.418 2.7129v1.0684a0.47257 0.47257 0 0 1-0.47266 0.47266h-2.8359a0.47257 0.47257 0 0 1-0.47266-0.47266v-1.0684c-0.85535-0.60016-1.418-1.5882-1.418-2.7129a3.308 3.308 0 0 1 3.3086-3.3086zm0 0.94531a2.3628 2.3628 0 0 0-2.3633 2.3633c0 0.96876 0.58153 1.8002 1.418 2.1641v1.1445h1.8906v-1.1445c0.83645-0.36388 1.418-1.1953 1.418-2.1641a2.3628 2.3628 0 0 0-2.3633-2.3633zm-1.418 7.5625h2.8359v0.47266a0.47257 0.47257 0 0 1-0.47266 0.47266h-1.8906a0.47257 0.47257 0 0 1-0.47266-0.47266z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }