var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MessageLayout",
    {
      attrs: {
        header: _vm.$tr("resetPasswordTitle"),
        text: _vm.$tr("resetPasswordPrompt"),
      },
    },
    [
      _c(
        "VForm",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("Banner", {
            staticClass: "mb-4",
            attrs: {
              text: _vm.$tr("resetPasswordFailed"),
              value: _vm.error,
              error: "",
            },
          }),
          _c("PasswordField", {
            attrs: {
              label: _vm.$tr("passwordLabel"),
              additionalRules: _vm.passwordValidationRules,
              autofocus: "",
            },
            model: {
              value: _vm.new_password1,
              callback: function ($$v) {
                _vm.new_password1 = $$v
              },
              expression: "new_password1",
            },
          }),
          _c("PasswordField", {
            attrs: {
              label: _vm.$tr("passwordConfirmLabel"),
              additionalRules: _vm.passwordConfirmRules,
            },
            model: {
              value: _vm.new_password2,
              callback: function ($$v) {
                _vm.new_password2 = $$v
              },
              expression: "new_password2",
            },
          }),
          _c("KButton", {
            staticClass: "w-100",
            attrs: {
              primary: "",
              text: _vm.$tr("submitButton"),
              type: "submit",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }