var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KIcon",
    _vm._g(
      _vm._b(
        { attrs: { icon: _vm.icon, color: _vm.color } },
        "KIcon",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }