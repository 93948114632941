var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: {
        title: _vm.$tr("changeLanguageModalHeader"),
        submitText: _vm.$tr("confirmAction"),
        cancelText: _vm.$tr("cancelAction"),
      },
      on: { cancel: _vm.cancel, submit: _vm.setLang },
    },
    [
      _c(
        "KGrid",
        _vm._l(_vm.splitLanguageOptions, function (languageCol, index) {
          return _c(
            "KGridItem",
            {
              key: index,
              class: { "offset-col": _vm.$vuetify.smAndDown && index === 1 },
              attrs: { layout8: { span: 4 }, layout12: { span: 6 } },
            },
            _vm._l(languageCol, function (language) {
              return _c("KRadioButton", {
                key: language.id,
                staticClass: "language-name",
                attrs: {
                  buttonValue: language.id,
                  label: language.lang_name,
                  title: language.english_name,
                },
                model: {
                  value: _vm.selectedLanguage,
                  callback: function ($$v) {
                    _vm.selectedLanguage = $$v
                  },
                  expression: "selectedLanguage",
                },
              })
            }),
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }