var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MessageLayout",
    { attrs: { header: _vm.$tr("title"), text: _vm.$tr("text") } },
    [
      _c("KRouterLink", {
        attrs: {
          primary: "",
          text: _vm.$tr("requestNewLink"),
          to: { name: "RequestNewActivationLink" },
          appearance: "raised-button",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }