var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PoliciesModal",
    {
      attrs: {
        policy: _vm.policy,
        title: _vm.title,
        needsAcceptance: _vm.needsAcceptance,
        "data-test": "community-standards-modal",
      },
      on: {
        accept: function ($event) {
          return _vm.$emit("accept", _vm.policy)
        },
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("p", [_vm._v(_vm._s(_vm.$tr("description")))]),
      _c(
        "p",
        [
          _c("ActionLink", {
            attrs: {
              href: "https://learningequality.org/about/values/",
              target: "_blank",
              text: _vm.$tr("coreValuesLink"),
            },
          }),
        ],
        1
      ),
      _c("p", [_vm._v(_vm._s(_vm.$tr("studioDescription")))]),
      _c("ul", { staticClass: "pb-4 px-4" }, [
        _c("li", [_vm._v(_vm._s(_vm.$tr("studioItem1")))]),
        _c("li", [_vm._v(_vm._s(_vm.$tr("studioItem2")))]),
        _c("li", [_vm._v(_vm._s(_vm.$tr("studioItem3")))]),
        _c("li", [_vm._v(_vm._s(_vm.$tr("studioItem4")))]),
        _c("li", [_vm._v(_vm._s(_vm.$tr("studioItem5")))]),
      ]),
      _c("p", [_vm._v(_vm._s(_vm.$tr("libraryDescription")))]),
      _c("ul", { staticClass: "pb-4 px-4" }, [
        _c("li", [_vm._v(_vm._s(_vm.$tr("libraryItem1")))]),
        _c("li", [_vm._v(_vm._s(_vm.$tr("libraryItem2")))]),
        _c("li", [_vm._v(_vm._s(_vm.$tr("libraryItem3")))]),
        _c("li", [_vm._v(_vm._s(_vm.$tr("libraryItem4")))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }