
<template>

  <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable -->
    <path d="M10.2415 3.6495H3.57479V10.3162H2.24146V3.6495C2.24146 3.29587 2.38193 2.95673 2.63198 2.70669C2.88203 2.45664 3.22117 2.31616 3.57479 2.31616H10.2415V3.6495ZM12.9081 4.98283H6.24146C5.88783 4.98283 5.54869 5.1233 5.29865 5.37335C5.0486 5.6234 4.90812 5.96254 4.90812 6.31616V12.9828H6.24146V6.31616H12.9081V4.98283ZM15.5748 8.98283V14.3162C15.5748 14.6698 15.4343 15.0089 15.1843 15.259C14.9342 15.509 14.5951 15.6495 14.2415 15.6495H8.90812C8.5545 15.6495 8.21536 15.509 7.96531 15.259C7.71526 15.0089 7.57479 14.6698 7.57479 14.3162V8.98283C7.57479 8.62921 7.71526 8.29007 7.96531 8.04002C8.21536 7.78997 8.5545 7.6495 8.90812 7.6495H14.2415C14.5951 7.6495 14.9342 7.78997 15.1843 8.04002C15.4343 8.29007 15.5748 8.62921 15.5748 8.98283ZM14.2415 10.9828H8.90812V12.3162H14.2415V10.9828Z" />
  </svg>

</template>
<script>

  export default {
    name: 'CollapseAllIcon',
  };

</script>
<style scoped>
	svg {
		fill: currentColor
	}
</style>
