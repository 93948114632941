var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VTextField",
    _vm._b(
      {
        attrs: {
          box: "",
          label: _vm.$tr("emailLabel"),
          required: _vm.required,
          rules: _vm.emailRules,
          "validate-on-blur": !_vm.validate,
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            _vm.validate = true
          },
          keydown: function ($event) {
            _vm.validate = false
          },
        },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      },
      "VTextField",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }