// Constant values for Languages sorted by native_name
const LanguagesMap = new Map([
  [
    'ach',
    {
      id: 'ach',
      lang_code: 'ach',
      lang_subcode: null,
      readable_name: 'Acholi; Acooli; Akoli',
      native_name: 'Acholi',
      lang_direction: 'ltr',
    },
  ],
  [
    'om',
    {
      id: 'om',
      lang_code: 'om',
      lang_subcode: null,
      readable_name: 'Oromo',
      native_name: 'Afaan Oromoo',
      lang_direction: 'ltr',
    },
  ],
  [
    'aa',
    {
      id: 'aa',
      lang_code: 'aa',
      lang_subcode: null,
      readable_name: 'Afar',
      native_name: 'Afaraf',
      lang_direction: 'ltr',
    },
  ],
  [
    'af',
    {
      id: 'af',
      lang_code: 'af',
      lang_subcode: null,
      readable_name: 'Afrikaans',
      native_name: 'Afrikaans',
      lang_direction: 'ltr',
    },
  ],
  [
    'ak',
    {
      id: 'ak',
      lang_code: 'ak',
      lang_subcode: null,
      readable_name: 'Akan',
      native_name: 'Akan',
      lang_direction: 'ltr',
    },
  ],
  [
    'aka',
    {
      id: 'aka',
      lang_code: 'aka',
      lang_subcode: null,
      readable_name: 'Akan',
      native_name: 'Akan',
      lang_direction: 'ltr',
    },
  ],
  [
    'rsn',
    {
      id: 'rsn',
      lang_code: 'rsn',
      lang_subcode: null,
      readable_name: 'Rwandan Sign Language',
      native_name: "Amarenga y'Ikinyarwanda",
      lang_direction: 'ltr',
    },
  ],
  [
    'an',
    {
      id: 'an',
      lang_code: 'an',
      lang_subcode: null,
      readable_name: 'Aragonese',
      native_name: 'Aragon\u00e9s',
      lang_direction: 'ltr',
    },
  ],
  [
    'en-PT',
    {
      id: 'en-PT',
      lang_code: 'en',
      lang_subcode: 'PT',
      readable_name: 'English, Pirate',
      native_name: 'Argh! Pirates!',
      lang_direction: 'ltr',
    },
  ],
  [
    'ig',
    {
      id: 'ig',
      lang_code: 'ig',
      lang_subcode: null,
      readable_name: 'Igbo',
      native_name: 'As\u1ee5s\u1ee5 Igbo',
      lang_direction: 'ltr',
    },
  ],
  [
    'ibo',
    {
      id: 'ibo',
      lang_code: 'ibo',
      lang_subcode: null,
      readable_name: 'Igbo',
      native_name: 'As\u1ee5s\u1ee5 Igbo',
      lang_direction: 'ltr',
    },
  ],
  [
    'gn',
    {
      id: 'gn',
      lang_code: 'gn',
      lang_subcode: null,
      readable_name: 'Guaran\u00ed',
      native_name: 'Ava\u00f1e\u1ebd',
      lang_direction: 'ltr',
    },
  ],
  [
    'id',
    {
      id: 'id',
      lang_code: 'id',
      lang_subcode: null,
      readable_name: 'Indonesian',
      native_name: 'Bahasa Indonesia',
      lang_direction: 'ltr',
    },
  ],
  [
    'su',
    {
      id: 'su',
      lang_code: 'su',
      lang_subcode: null,
      readable_name: 'Sundanese',
      native_name: 'Basa Sunda',
      lang_direction: 'ltr',
    },
  ],
  [
    'bi',
    {
      id: 'bi',
      lang_code: 'bi',
      lang_subcode: null,
      readable_name: 'Bislama',
      native_name: 'Bislama',
      lang_direction: 'ltr',
    },
  ],
  [
    'brh',
    {
      id: 'brh',
      lang_code: 'brh',
      lang_subcode: null,
      readable_name: 'Brahui',
      native_name: 'Brahui',
      lang_direction: 'ltr',
    },
  ],
  [
    'en-GB',
    {
      id: 'en-GB',
      lang_code: 'en',
      lang_subcode: 'GB',
      readable_name: 'English, Britain',
      native_name: 'British English',
      lang_direction: 'ltr',
    },
  ],
  [
    'bug',
    {
      id: 'bug',
      lang_code: 'bug',
      lang_subcode: null,
      readable_name: 'Buginese',
      native_name: 'Buginese',
      lang_direction: 'ltr',
    },
  ],
  [
    'bxk',
    {
      id: 'bxk',
      lang_code: 'bxk',
      lang_subcode: null,
      readable_name: 'Bukusu',
      native_name: 'Bukusu',
      lang_direction: 'ltr',
    },
  ],
  [
    'csx',
    {
      id: 'csx',
      lang_code: 'csx',
      lang_subcode: null,
      readable_name: 'Cambodian Sign Language',
      native_name: 'Cambodian Sign Language',
      lang_direction: 'ltr',
    },
  ],
  [
    'ca',
    {
      id: 'ca',
      lang_code: 'ca',
      lang_subcode: null,
      readable_name: 'Catalan; Valencian',
      native_name: 'Catal\u00e0',
      lang_direction: 'ltr',
    },
  ],
  [
    'ceb',
    {
      id: 'ceb',
      lang_code: 'ceb',
      lang_subcode: null,
      readable_name: 'Cebuano',
      native_name: 'Cebuano',
      lang_direction: 'ltr',
    },
  ],
  [
    'ch',
    {
      id: 'ch',
      lang_code: 'ch',
      lang_subcode: null,
      readable_name: 'Chamorro',
      native_name: 'Chamoru',
      lang_direction: 'ltr',
    },
  ],
  [
    'cho',
    {
      id: 'cho',
      lang_code: 'cho',
      lang_subcode: null,
      readable_name: 'Choctaw',
      native_name: 'Choctaw',
      lang_direction: 'ltr',
    },
  ],
  [
    'cy',
    {
      id: 'cy',
      lang_code: 'cy',
      lang_subcode: null,
      readable_name: 'Welsh',
      native_name: 'Cymraeg',
      lang_direction: 'ltr',
    },
  ],
  [
    'thl',
    {
      id: 'thl',
      lang_code: 'thl',
      lang_subcode: null,
      readable_name: 'Dangaura Tharu',
      native_name: 'Dangaura Tharu',
      lang_direction: 'ltr',
    },
  ],
  [
    'da',
    {
      id: 'da',
      lang_code: 'da',
      lang_subcode: null,
      readable_name: 'Danish',
      native_name: 'Dansk',
      lang_direction: 'ltr',
    },
  ],
  [
    'se',
    {
      id: 'se',
      lang_code: 'se',
      lang_subcode: null,
      readable_name: 'Northern Sami',
      native_name: 'Davvis\u00e1megiella',
      lang_direction: 'ltr',
    },
  ],
  [
    'de',
    {
      id: 'de',
      lang_code: 'de',
      lang_subcode: null,
      readable_name: 'German',
      native_name: 'Deutsch',
      lang_direction: 'ltr',
    },
  ],
  [
    'nv',
    {
      id: 'nv',
      lang_code: 'nv',
      lang_subcode: null,
      readable_name: 'Navajo; Navaho',
      native_name: 'Din\u00e9 bizaad, Din\u00e9k\u02bceh\u01f0\u00ed',
      lang_direction: 'ltr',
    },
  ],
  [
    'dty',
    {
      id: 'dty',
      lang_code: 'dty',
      lang_subcode: null,
      readable_name: 'Dotyali',
      native_name: 'Dotyali',
      lang_direction: 'ltr',
    },
  ],
  [
    'taj',
    {
      id: 'taj',
      lang_code: 'taj',
      lang_subcode: null,
      readable_name: 'Eastern Tamang',
      native_name: 'Eastern Tamang',
      lang_direction: 'ltr',
    },
  ],
  [
    'efi',
    {
      id: 'efi',
      lang_code: 'efi',
      lang_subcode: null,
      readable_name: 'Efik',
      native_name: 'Efik',
      lang_direction: 'ltr',
    },
  ],
  [
    'na',
    {
      id: 'na',
      lang_code: 'na',
      lang_subcode: null,
      readable_name: 'Nauru',
      native_name: 'Ekakair\u0169 Naoero',
      lang_direction: 'ltr',
    },
  ],
  [
    'guz',
    {
      id: 'guz',
      lang_code: 'guz',
      lang_subcode: null,
      readable_name: 'Gusii',
      native_name: 'Ekegusii',
      lang_direction: 'ltr',
    },
  ],
  [
    'enq',
    {
      id: 'enq',
      lang_code: 'enq',
      lang_subcode: null,
      readable_name: 'Enga',
      native_name: 'Enga',
      lang_direction: 'ltr',
    },
  ],
  [
    'en',
    {
      id: 'en',
      lang_code: 'en',
      lang_subcode: null,
      readable_name: 'English',
      native_name: 'English',
      lang_direction: 'ltr',
    },
  ],
  [
    'es',
    {
      id: 'es',
      lang_code: 'es',
      lang_subcode: null,
      readable_name: 'Spanish',
      native_name: 'Espa\u00f1ol',
      lang_direction: 'ltr',
    },
  ],
  [
    'es-AR',
    {
      id: 'es-AR',
      lang_code: 'es',
      lang_subcode: 'AR',
      readable_name: 'Spanish, Argentina',
      native_name: 'Espa\u00f1ol (Argentina)',
      lang_direction: 'ltr',
    },
  ],
  [
    'es-ES',
    {
      id: 'es-ES',
      lang_code: 'es',
      lang_subcode: 'ES',
      readable_name: 'Spanish, Spain; Castilian',
      native_name: 'Espa\u00f1ol (Espa\u00f1a), Castellano',
      lang_direction: 'ltr',
    },
  ],
  [
    'es-MX',
    {
      id: 'es-MX',
      lang_code: 'es',
      lang_subcode: 'MX',
      readable_name: 'Spanish, Mexico',
      native_name: 'Espa\u00f1ol (Mexico)',
      lang_direction: 'ltr',
    },
  ],
  [
    'es-NI',
    {
      id: 'es-NI',
      lang_code: 'es',
      lang_subcode: 'NI',
      readable_name: 'Spanish, Nicaragua',
      native_name: 'Espa\u00f1ol (Nicaragua)',
      lang_direction: 'ltr',
    },
  ],
  [
    'eo',
    {
      id: 'eo',
      lang_code: 'eo',
      lang_subcode: null,
      readable_name: 'Esperanto',
      native_name: 'Esperanto',
      lang_direction: 'ltr',
    },
  ],
  [
    'ee',
    {
      id: 'ee',
      lang_code: 'ee',
      lang_subcode: null,
      readable_name: 'Ewe',
      native_name: 'E\u028begbe',
      lang_direction: 'ltr',
    },
  ],
  [
    'fr-CA',
    {
      id: 'fr-CA',
      lang_code: 'fr',
      lang_subcode: 'CA',
      readable_name: 'French, Canada',
      native_name: 'Fran\u00e7ais (Canada)',
      lang_direction: 'ltr',
    },
  ],
  [
    'fr',
    {
      id: 'fr',
      lang_code: 'fr',
      lang_subcode: null,
      readable_name: 'French',
      native_name: 'Fran\u00e7ais, langue fran\u00e7aise',
      lang_direction: 'ltr',
    },
  ],
  [
    'fy',
    {
      id: 'fy',
      lang_code: 'fy',
      lang_subcode: null,
      readable_name: 'Western Frisian',
      native_name: 'Frysk',
      lang_direction: 'ltr',
    },
  ],
  [
    'fy-NL',
    {
      id: 'fy-NL',
      lang_code: 'fy',
      lang_subcode: 'NL',
      readable_name: 'Frisian',
      native_name: 'Frysk',
      lang_direction: 'ltr',
    },
  ],
  [
    'ful',
    {
      id: 'ful',
      lang_code: 'ful',
      lang_subcode: null,
      readable_name: 'Fula; Fulah',
      native_name: 'Fulfulde',
      lang_direction: 'ltr',
    },
  ],
  [
    'fuv',
    {
      id: 'fuv',
      lang_code: 'fuv',
      lang_subcode: null,
      readable_name: 'Fulfulde Mbororo; Fulfulde Mbororoore',
      native_name: 'Fulfulde',
      lang_direction: 'ltr',
    },
  ],
  [
    'ff',
    {
      id: 'ff',
      lang_code: 'ff',
      lang_subcode: null,
      readable_name: 'Fula; Fulah; Pulaar; Pular',
      native_name: 'Fulfulde, Pulaar, Pular',
      lang_direction: 'ltr',
    },
  ],
  [
    'ga',
    {
      id: 'ga',
      lang_code: 'ga',
      lang_subcode: null,
      readable_name: 'Irish',
      native_name: 'Gaeilge',
      lang_direction: 'ltr',
    },
  ],
  [
    'gv',
    {
      id: 'gv',
      lang_code: 'gv',
      lang_subcode: null,
      readable_name: 'Manx',
      native_name: 'Gaelg, Gailck',
      lang_direction: 'ltr',
    },
  ],
  [
    'gl',
    {
      id: 'gl',
      lang_code: 'gl',
      lang_subcode: null,
      readable_name: 'Galician',
      native_name: 'Galego',
      lang_direction: 'ltr',
    },
  ],
  [
    'gd',
    {
      id: 'gd',
      lang_code: 'gd',
      lang_subcode: null,
      readable_name: 'Scottish Gaelic; Gaelic',
      native_name: 'G\u00e0idhlig',
      lang_direction: 'ltr',
    },
  ],
  [
    'ki',
    {
      id: 'ki',
      lang_code: 'ki',
      lang_subcode: null,
      readable_name: 'Kikuyu; Gikuyu',
      native_name: 'G\u0129k\u0169y\u0169',
      lang_direction: 'ltr',
    },
  ],
  [
    'kik',
    {
      id: 'kik',
      lang_code: 'kik',
      lang_subcode: null,
      readable_name: 'Kikuyu',
      native_name: 'G\u0129k\u0169y\u0169',
      lang_direction: 'ltr',
    },
  ],
  [
    'hdy',
    {
      id: 'hdy',
      lang_code: 'hdy',
      lang_subcode: null,
      readable_name: 'Hadiyya; Hadiya; Adea; Adiya; Hadia',
      native_name: 'Hadiyyisa',
      lang_direction: 'ltr',
    },
  ],
  [
    'ha',
    {
      id: 'ha',
      lang_code: 'ha',
      lang_subcode: null,
      readable_name: 'Hausa',
      native_name: 'Hausa, \u0647\u064e\u0648\u064f\u0633\u064e',
      lang_direction: 'ltr',
    },
  ],
  [
    'hau',
    {
      id: 'hau',
      lang_code: 'hau',
      lang_subcode: null,
      readable_name: 'Hausa',
      native_name: 'Hausa, \u0647\u064e\u0648\u064f\u0633\u064e',
      lang_direction: 'ltr',
    },
  ],
  [
    'ho',
    {
      id: 'ho',
      lang_code: 'ho',
      lang_subcode: null,
      readable_name: 'Hiri Motu',
      native_name: 'Hiri Motu',
      lang_direction: 'ltr',
    },
  ],
  [
    'hrx',
    {
      id: 'hrx',
      lang_code: 'hrx',
      lang_subcode: null,
      readable_name: 'Hunsrik',
      native_name: 'Hunsr\u00fcckisch, Hunsr\u00fcck, Hunsriker',
      lang_direction: 'ltr',
    },
  ],
  [
    'io',
    {
      id: 'io',
      lang_code: 'io',
      lang_subcode: null,
      readable_name: 'Ido',
      native_name: 'Ido',
      lang_direction: 'ltr',
    },
  ],
  [
    'rw',
    {
      id: 'rw',
      lang_code: 'rw',
      lang_subcode: null,
      readable_name: 'Kinyarwanda',
      native_name: 'Ikinyarwanda',
      lang_direction: 'ltr',
    },
  ],
  [
    'ia',
    {
      id: 'ia',
      lang_code: 'ia',
      lang_subcode: null,
      readable_name: 'Interlingua',
      native_name: 'Interlingua',
      lang_direction: 'ltr',
    },
  ],
  [
    'it',
    {
      id: 'it',
      lang_code: 'it',
      lang_subcode: null,
      readable_name: 'Italian',
      native_name: 'Italiano',
      lang_direction: 'ltr',
    },
  ],
  [
    'ik',
    {
      id: 'ik',
      lang_code: 'ik',
      lang_subcode: null,
      readable_name: 'Inupiaq',
      native_name: 'I\u00f1upiaq, I\u00f1upiatun',
      lang_direction: 'ltr',
    },
  ],
  [
    'mh',
    {
      id: 'mh',
      lang_code: 'mh',
      lang_subcode: null,
      readable_name: 'Marshallese',
      native_name: 'Kajin M\u0327aje\u013c',
      lang_direction: 'ltr',
    },
  ],
  [
    'kak',
    {
      id: 'kak',
      lang_code: 'kak',
      lang_subcode: null,
      readable_name: 'Kalanguya',
      native_name: 'Kalanguya',
      lang_direction: 'ltr',
    },
  ],
  [
    'kr',
    {
      id: 'kr',
      lang_code: 'kr',
      lang_subcode: null,
      readable_name: 'Kanuri',
      native_name: 'Kanuri',
      lang_direction: 'ltr',
    },
  ],
  [
    'kw',
    {
      id: 'kw',
      lang_code: 'kw',
      lang_subcode: null,
      readable_name: 'Cornish',
      native_name: 'Kernewek',
      lang_direction: 'ltr',
    },
  ],
  [
    'kg',
    {
      id: 'kg',
      lang_code: 'kg',
      lang_subcode: null,
      readable_name: 'Kongo',
      native_name: 'KiKongo',
      lang_direction: 'ltr',
    },
  ],
  [
    'kon',
    {
      id: 'kon',
      lang_code: 'kon',
      lang_subcode: null,
      readable_name: 'Kongo',
      native_name: 'KiKongo',
      lang_direction: 'ltr',
    },
  ],
  [
    'kam',
    {
      id: 'kam',
      lang_code: 'kam',
      lang_subcode: null,
      readable_name: 'Kamba (Kenya)',
      native_name: 'Kikamba',
      lang_direction: 'ltr',
    },
  ],
  [
    'sw',
    {
      id: 'sw',
      lang_code: 'sw',
      lang_subcode: null,
      readable_name: 'Swahili',
      native_name: 'Kiswahili',
      lang_direction: 'ltr',
    },
  ],
  [
    'swa',
    {
      id: 'swa',
      lang_code: 'swa',
      lang_subcode: null,
      readable_name: 'Swahili',
      native_name: 'Kiswahili',
      lang_direction: 'ltr',
    },
  ],
  [
    'ht',
    {
      id: 'ht',
      lang_code: 'ht',
      lang_subcode: null,
      readable_name: 'Haitian; Haitian Creole',
      native_name: 'Krey\u00f2l ayisyen',
      lang_direction: 'ltr',
    },
  ],
  [
    'kj',
    {
      id: 'kj',
      lang_code: 'kj',
      lang_subcode: null,
      readable_name: 'Kwanyama; Kuanyama',
      native_name: 'Kuanyama',
      lang_direction: 'ltr',
    },
  ],
  [
    'ku',
    {
      id: 'ku',
      lang_code: 'ku',
      lang_subcode: null,
      readable_name: 'Kurdish',
      native_name: 'Kurd\u00ee, \u0643\u0648\u0631\u062f\u06cc\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'lkt',
    {
      id: 'lkt',
      lang_code: 'lkt',
      lang_subcode: null,
      readable_name: 'Lakhota; Lakotiyapi; Teton',
      native_name: 'Lakhota',
      lang_direction: 'ltr',
    },
  ],
  [
    'bma',
    {
      id: 'bma',
      lang_code: 'bma',
      lang_subcode: null,
      readable_name: 'Lame',
      native_name: 'Lame',
      lang_direction: 'ltr',
    },
  ],
  [
    'li',
    {
      id: 'li',
      lang_code: 'li',
      lang_subcode: null,
      readable_name: 'Limburgish; Limburgan; Limburger',
      native_name: 'Limburgs',
      lang_direction: 'ltr',
    },
  ],
  [
    'ln',
    {
      id: 'ln',
      lang_code: 'ln',
      lang_subcode: null,
      readable_name: 'Lingala',
      native_name: 'Ling\u00e1la',
      lang_direction: 'ltr',
    },
  ],
  [
    'lin',
    {
      id: 'lin',
      lang_code: 'lin',
      lang_subcode: null,
      readable_name: 'Lingala',
      native_name: 'Ling\u00e1la',
      lang_direction: 'ltr',
    },
  ],
  [
    'lua',
    {
      id: 'lua',
      lang_code: 'lua',
      lang_subcode: null,
      readable_name: 'Luba-Kasai',
      native_name: 'Luba-Kasai, Tshiluba',
      lang_direction: 'ltr',
    },
  ],
  [
    'lu',
    {
      id: 'lu',
      lang_code: 'lu',
      lang_subcode: null,
      readable_name: 'Luba-Katanga',
      native_name: 'Luba-Katanga',
      lang_direction: 'ltr',
    },
  ],
  [
    'lg',
    {
      id: 'lg',
      lang_code: 'lg',
      lang_subcode: null,
      readable_name: 'Luganda',
      native_name: 'Luganda',
      lang_direction: 'ltr',
    },
  ],
  [
    'myx',
    {
      id: 'myx',
      lang_code: 'myx',
      lang_subcode: null,
      readable_name: 'Masaaba; Bagisu',
      native_name: 'Lumasaaba, Masaaba',
      lang_direction: 'ltr',
    },
  ],
  [
    'xog',
    {
      id: 'xog',
      lang_code: 'xog',
      lang_subcode: null,
      readable_name: 'Soga',
      native_name: 'Lusoga, Olusoga',
      lang_direction: 'ltr',
    },
  ],
  [
    'lb',
    {
      id: 'lb',
      lang_code: 'lb',
      lang_subcode: null,
      readable_name: 'Luxembourgish; Letzeburgesch',
      native_name: 'L\u00ebtzebuergesch',
      lang_direction: 'ltr',
    },
  ],
  [
    'hu',
    {
      id: 'hu',
      lang_code: 'hu',
      lang_subcode: null,
      readable_name: 'Hungarian',
      native_name: 'Magyar',
      lang_direction: 'ltr',
    },
  ],
  [
    'mg',
    {
      id: 'mg',
      lang_code: 'mg',
      lang_subcode: null,
      readable_name: 'Malagasy',
      native_name: 'Malagasy fiteny',
      lang_direction: 'ltr',
    },
  ],
  [
    'mt',
    {
      id: 'mt',
      lang_code: 'mt',
      lang_subcode: null,
      readable_name: 'Maltese',
      native_name: 'Malti',
      lang_direction: 'ltr',
    },
  ],
  [
    'mul',
    {
      id: 'mul',
      lang_code: 'mul',
      lang_subcode: null,
      readable_name: 'Multiple languages',
      native_name: 'Multiple languages',
      lang_direction: 'ltr',
    },
  ],
  [
    'nl',
    {
      id: 'nl',
      lang_code: 'nl',
      lang_subcode: null,
      readable_name: 'Dutch',
      native_name: 'Nederlands, Vlaams',
      lang_direction: 'ltr',
    },
  ],
  [
    'tuv',
    {
      id: 'tuv',
      lang_code: 'tuv',
      lang_subcode: null,
      readable_name: 'Turkana; Turkwana; Buma; Bume',
      native_name: "Ng\u2019aturkana, Ng'aturkana",
      lang_direction: 'ltr',
    },
  ],
  [
    'no',
    {
      id: 'no',
      lang_code: 'no',
      lang_subcode: null,
      readable_name: 'Norwegian',
      native_name: 'Norsk',
      lang_direction: 'ltr',
    },
  ],
  [
    'nb',
    {
      id: 'nb',
      lang_code: 'nb',
      lang_subcode: null,
      readable_name: 'Norwegian Bokm\u00e5l',
      native_name: 'Norsk bokm\u00e5l',
      lang_direction: 'ltr',
    },
  ],
  [
    'nn',
    {
      id: 'nn',
      lang_code: 'nn',
      lang_subcode: null,
      readable_name: 'Norwegian Nynorsk',
      native_name: 'Norsk nynorsk',
      lang_direction: 'ltr',
    },
  ],
  [
    'pbu',
    {
      id: 'pbu',
      lang_code: 'pbu',
      lang_subcode: null,
      readable_name: 'Northern Pashto',
      native_name: 'Northern Pashto',
      lang_direction: 'ltr',
    },
  ],
  [
    'oc',
    {
      id: 'oc',
      lang_code: 'oc',
      lang_subcode: null,
      readable_name: 'Occitan',
      native_name: 'Occitan',
      lang_direction: 'ltr',
    },
  ],
  [
    'lwg',
    {
      id: 'lwg',
      lang_code: 'lwg',
      lang_subcode: null,
      readable_name: 'Oluwanga; Oluhanga; Hanga; Kawanga',
      native_name: 'Oluwanga',
      lang_direction: 'ltr',
    },
  ],
  [
    'ie',
    {
      id: 'ie',
      lang_code: 'ie',
      lang_subcode: null,
      readable_name: 'Interlingue',
      native_name: 'Originally called Occidental; then Interlingue after WWII',
      lang_direction: 'ltr',
    },
  ],
  [
    'hz',
    {
      id: 'hz',
      lang_code: 'hz',
      lang_subcode: null,
      readable_name: 'Herero',
      native_name: 'Otjiherero',
      lang_direction: 'ltr',
    },
  ],
  [
    'ng',
    {
      id: 'ng',
      lang_code: 'ng',
      lang_subcode: null,
      readable_name: 'Ndonga',
      native_name: 'Owambo',
      lang_direction: 'ltr',
    },
  ],
  [
    'pms',
    {
      id: 'pms',
      lang_code: 'pms',
      lang_subcode: null,
      readable_name: 'Piedmontese',
      native_name: 'Piemont\u00e8is',
      lang_direction: 'ltr',
    },
  ],
  [
    'fil',
    {
      id: 'fil',
      lang_code: 'fil',
      lang_subcode: null,
      readable_name: 'Filipino',
      native_name: 'Pilipino',
      lang_direction: 'ltr',
    },
  ],
  [
    'pl',
    {
      id: 'pl',
      lang_code: 'pl',
      lang_subcode: null,
      readable_name: 'Polish',
      native_name: 'Polski',
      lang_direction: 'ltr',
    },
  ],
  [
    'pt',
    {
      id: 'pt',
      lang_code: 'pt',
      lang_subcode: null,
      readable_name: 'Portuguese',
      native_name: 'Portugu\u00eas',
      lang_direction: 'ltr',
    },
  ],
  [
    'pt-BR',
    {
      id: 'pt-BR',
      lang_code: 'pt',
      lang_subcode: 'BR',
      readable_name: 'Portuguese, Brazil',
      native_name: 'Portugu\u00eas (Brasil)',
      lang_direction: 'ltr',
    },
  ],
  [
    'pt-PT',
    {
      id: 'pt-PT',
      lang_code: 'pt',
      lang_subcode: 'PT',
      readable_name: 'Portuguese, Portugal',
      native_name: 'Portugu\u00eas (Portugal)',
      lang_direction: 'ltr',
    },
  ],
  [
    'ty',
    {
      id: 'ty',
      lang_code: 'ty',
      lang_subcode: null,
      readable_name: 'Tahitian',
      native_name: 'Reo Tahiti',
      lang_direction: 'ltr',
    },
  ],
  [
    'que',
    {
      id: 'que',
      lang_code: 'que',
      lang_subcode: null,
      readable_name: 'Quechua',
      native_name: 'Runa Simi',
      lang_direction: 'ltr',
    },
  ],
  [
    'qu',
    {
      id: 'qu',
      lang_code: 'qu',
      lang_subcode: null,
      readable_name: 'Quechua',
      native_name: 'Runa Simi, Kichwa',
      lang_direction: 'ltr',
    },
  ],
  [
    'nyn',
    {
      id: 'nyn',
      lang_code: 'nyn',
      lang_subcode: null,
      readable_name: 'Nyankore; Nkole; Nyankole',
      native_name: 'Runyankore',
      lang_direction: 'ltr',
    },
  ],
  [
    'za',
    {
      id: 'za',
      lang_code: 'za',
      lang_subcode: null,
      readable_name: 'Zhuang; Chuang',
      native_name: 'Sa\u026f cue\u014b\u0185, Saw cuengh',
      lang_direction: 'ltr',
    },
  ],
  [
    'sco',
    {
      id: 'sco',
      lang_code: 'sco',
      lang_subcode: null,
      readable_name: 'Scots',
      native_name: 'Scots',
      lang_direction: 'ltr',
    },
  ],
  [
    'srr',
    {
      id: 'srr',
      lang_code: 'srr',
      lang_subcode: null,
      readable_name: 'Serer',
      native_name: 'Seereer',
      lang_direction: 'ltr',
    },
  ],
  [
    'nso',
    {
      id: 'nso',
      lang_code: 'nso',
      lang_subcode: null,
      readable_name: 'Northern Sotho',
      native_name: 'Sepedi, Sesotho sa Leboa, Pedi',
      lang_direction: 'ltr',
    },
  ],
  [
    'st',
    {
      id: 'st',
      lang_code: 'st',
      lang_subcode: null,
      readable_name: 'Southern Sotho',
      native_name: 'Sesotho',
      lang_direction: 'ltr',
    },
  ],
  [
    'sot',
    {
      id: 'sot',
      lang_code: 'sot',
      lang_subcode: null,
      readable_name: 'Southern Sotho',
      native_name: 'Sesotho',
      lang_direction: 'ltr',
    },
  ],
  [
    'tn',
    {
      id: 'tn',
      lang_code: 'tn',
      lang_subcode: null,
      readable_name: 'Tswana',
      native_name: 'Setswana',
      lang_direction: 'ltr',
    },
  ],
  [
    'tsn',
    {
      id: 'tsn',
      lang_code: 'tsn',
      lang_subcode: null,
      readable_name: 'Tswana',
      native_name: 'Setswana',
      lang_direction: 'ltr',
    },
  ],
  [
    'sq',
    {
      id: 'sq',
      lang_code: 'sq',
      lang_subcode: null,
      readable_name: 'Albanian',
      native_name: 'Shqip',
      lang_direction: 'ltr',
    },
  ],
  [
    'ss',
    {
      id: 'ss',
      lang_code: 'ss',
      lang_subcode: null,
      readable_name: 'Swati',
      native_name: 'SiSwati',
      lang_direction: 'ltr',
    },
  ],
  [
    'sid',
    {
      id: 'sid',
      lang_code: 'sid',
      lang_subcode: null,
      readable_name: 'Sidamo; Sidaamu afii; Sidaama; Sidama',
      native_name: 'Sidaamu Afoo',
      lang_direction: 'ltr',
    },
  ],
  [
    'som',
    {
      id: 'som',
      lang_code: 'som',
      lang_subcode: null,
      readable_name: 'Somali',
      native_name: 'Soomaaliga',
      lang_direction: 'ltr',
    },
  ],
  [
    'so',
    {
      id: 'so',
      lang_code: 'so',
      lang_subcode: null,
      readable_name: 'Somali',
      native_name: 'Soomaaliga, af Soomaali',
      lang_direction: 'ltr',
    },
  ],
  [
    'bcc',
    {
      id: 'bcc',
      lang_code: 'bcc',
      lang_subcode: null,
      readable_name: 'Southern Balochi',
      native_name: 'Southern Balochi',
      lang_direction: 'ltr',
    },
  ],
  [
    'pbt',
    {
      id: 'pbt',
      lang_code: 'pbt',
      lang_subcode: null,
      readable_name: 'Southern Pashto',
      native_name: 'Southern Pashto',
      lang_direction: 'ltr',
    },
  ],
  [
    'sv-SE',
    {
      id: 'sv-SE',
      lang_code: 'sv',
      lang_subcode: 'SE',
      readable_name: 'Swedish',
      native_name: 'Svenska',
      lang_direction: 'ltr',
    },
  ],
  [
    'sv-FI',
    {
      id: 'sv-FI',
      lang_code: 'sv',
      lang_subcode: 'FI',
      readable_name: 'Swedish',
      native_name: 'Svenska',
      lang_direction: 'ltr',
    },
  ],
  [
    'xki',
    {
      id: 'xki',
      lang_code: 'xki',
      lang_subcode: null,
      readable_name: 'Kenyan Sign Language',
      native_name: 'Swahili Lugha ya ishara',
      lang_direction: 'ltr',
    },
  ],
  [
    'be-tara',
    {
      id: 'be-tara',
      lang_code: 'be',
      lang_subcode: 'tara',
      readable_name: 'Belarusian, Classical Orthography',
      native_name:
        'Tara\u0161kievica, \u0442\u0430\u0440\u0430\u0448\u043a\u0435\u0432\u0456\u0446\u0430, \u0411\u0435\u043b\u0430\u0440\u0443\u0441\u043a\u0430\u044f \u043a\u043b\u044f\u0441\u044b\u0447\u043d\u044b \u043f\u0440\u0430\u0432\u0430\u043f\u0456\u0441',
      lang_direction: 'ltr',
    },
  ],
  [
    'vi',
    {
      id: 'vi',
      lang_code: 'vi',
      lang_subcode: null,
      readable_name: 'Vietnamese',
      native_name: 'Ti\u1ebfng Vi\u1ec7t',
      lang_direction: 'ltr',
    },
  ],
  [
    'tpi',
    {
      id: 'tpi',
      lang_code: 'tpi',
      lang_subcode: null,
      readable_name: 'Tok Pisin',
      native_name: 'Tok Pisin',
      lang_direction: 'ltr',
    },
  ],
  [
    've',
    {
      id: 've',
      lang_code: 've',
      lang_subcode: null,
      readable_name: 'Venda',
      native_name: 'Tshiven\u1e13a, Tshivenda',
      lang_direction: 'ltr',
    },
  ],
  [
    'tw',
    {
      id: 'tw',
      lang_code: 'tw',
      lang_subcode: null,
      readable_name: 'Twi',
      native_name: 'Twi',
      lang_direction: 'ltr',
    },
  ],
  [
    'tk',
    {
      id: 'tk',
      lang_code: 'tk',
      lang_subcode: null,
      readable_name: 'Turkmen',
      native_name: 'T\u00fcrkmen, \u0422\u04af\u0440\u043a\u043c\u0435\u043d',
      lang_direction: 'ltr',
    },
  ],
  [
    'tr',
    {
      id: 'tr',
      lang_code: 'tr',
      lang_subcode: null,
      readable_name: 'Turkish',
      native_name: 'T\u00fcrk\u00e7e',
      lang_direction: 'ltr',
    },
  ],
  [
    'und',
    {
      id: 'und',
      lang_code: 'und',
      lang_subcode: null,
      readable_name: 'Undetermined',
      native_name: 'Undetermined',
      lang_direction: 'ltr',
    },
  ],
  [
    'ur-PK',
    {
      id: 'ur-PK',
      lang_code: 'ur',
      lang_subcode: 'PK',
      readable_name: 'Urdu',
      native_name: 'Urdu',
      lang_direction: 'rtl',
    },
  ],
  [
    'ug',
    {
      id: 'ug',
      lang_code: 'ug',
      lang_subcode: null,
      readable_name: 'Uighur; Uyghur',
      native_name: 'Uy\u01a3urq\u0259, \u0626\u06c7\u064a\u063a\u06c7\u0631\u0686\u06d5\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'vo',
    {
      id: 'vo',
      lang_code: 'vo',
      lang_subcode: null,
      readable_name: 'Volap\u00fck',
      native_name: 'Volap\u00fck',
      lang_direction: 'ltr',
    },
  ],
  [
    'wa',
    {
      id: 'wa',
      lang_code: 'wa',
      lang_subcode: null,
      readable_name: 'Walloon',
      native_name: 'Walon',
      lang_direction: 'ltr',
    },
  ],
  [
    'tl',
    {
      id: 'tl',
      lang_code: 'tl',
      lang_subcode: null,
      readable_name: 'Tagalog',
      native_name: 'Wikang Tagalog',
      lang_direction: 'ltr',
    },
  ],
  [
    'wal',
    {
      id: 'wal',
      lang_code: 'wal',
      lang_subcode: null,
      readable_name: 'Wolaytta; Borodda; Uba; Ometo',
      native_name: 'Wolaytta',
      lang_direction: 'ltr',
    },
  ],
  [
    'wo',
    {
      id: 'wo',
      lang_code: 'wo',
      lang_subcode: null,
      readable_name: 'Wolof',
      native_name: 'Wollof',
      lang_direction: 'ltr',
    },
  ],
  [
    'wol',
    {
      id: 'wol',
      lang_code: 'wol',
      lang_subcode: null,
      readable_name: 'Wolof',
      native_name: 'Wollof',
      lang_direction: 'ltr',
    },
  ],
  [
    'ts',
    {
      id: 'ts',
      lang_code: 'ts',
      lang_subcode: null,
      readable_name: 'Tsonga',
      native_name: 'Xitsonga',
      lang_direction: 'ltr',
    },
  ],
  [
    'yao',
    {
      id: 'yao',
      lang_code: 'yao',
      lang_subcode: null,
      readable_name: 'Yao',
      native_name: 'Yao',
      lang_direction: 'ltr',
    },
  ],
  [
    'yo',
    {
      id: 'yo',
      lang_code: 'yo',
      lang_subcode: null,
      readable_name: 'Yoruba',
      native_name: 'Yor\u00f9b\u00e1',
      lang_direction: 'ltr',
    },
  ],
  [
    'yor',
    {
      id: 'yor',
      lang_code: 'yor',
      lang_subcode: null,
      readable_name: 'Yoruba',
      native_name: 'Yor\u00f9b\u00e1',
      lang_direction: 'ltr',
    },
  ],
  [
    'ae',
    {
      id: 'ae',
      lang_code: 'ae',
      lang_subcode: null,
      readable_name: 'Avestan',
      native_name: 'avesta',
      lang_direction: 'ltr',
    },
  ],
  [
    'ay',
    {
      id: 'ay',
      lang_code: 'ay',
      lang_subcode: null,
      readable_name: 'Aymara',
      native_name: 'aymar aru',
      lang_direction: 'ltr',
    },
  ],
  [
    'az',
    {
      id: 'az',
      lang_code: 'az',
      lang_subcode: null,
      readable_name: 'Azerbaijani',
      native_name: 'az\u0259rbaycan dili',
      lang_direction: 'ltr',
    },
  ],
  [
    'ms',
    {
      id: 'ms',
      lang_code: 'ms',
      lang_subcode: null,
      readable_name: 'Malay',
      native_name: 'bahasa Melayu, \u0628\u0647\u0627\u0633 \u0645\u0644\u0627\u064a\u0648\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'bm',
    {
      id: 'bm',
      lang_code: 'bm',
      lang_subcode: null,
      readable_name: 'Bambara',
      native_name: 'bamanankan',
      lang_direction: 'ltr',
    },
  ],
  [
    'jv',
    {
      id: 'jv',
      lang_code: 'jv',
      lang_subcode: null,
      readable_name: 'Javanese',
      native_name: 'basa Jawa',
      lang_direction: 'ltr',
    },
  ],
  [
    'bs',
    {
      id: 'bs',
      lang_code: 'bs',
      lang_subcode: null,
      readable_name: 'Bosnian',
      native_name: 'bosanski jezik',
      lang_direction: 'ltr',
    },
  ],
  [
    'br',
    {
      id: 'br',
      lang_code: 'br',
      lang_subcode: null,
      readable_name: 'Breton',
      native_name: 'brezhoneg',
      lang_direction: 'ltr',
    },
  ],
  [
    'nya',
    {
      id: 'nya',
      lang_code: 'nya',
      lang_subcode: null,
      readable_name: 'Chichewa',
      native_name: 'chiChe\u0175a',
      lang_direction: 'ltr',
    },
  ],
  [
    'ny',
    {
      id: 'ny',
      lang_code: 'ny',
      lang_subcode: null,
      readable_name: 'Chichewa; Chewa; Nyanja',
      native_name: 'chiChe\u0175a, chinyanja',
      lang_direction: 'ltr',
    },
  ],
  [
    'sn',
    {
      id: 'sn',
      lang_code: 'sn',
      lang_subcode: null,
      readable_name: 'Shona',
      native_name: 'chiShona',
      lang_direction: 'ltr',
    },
  ],
  [
    'sna',
    {
      id: 'sna',
      lang_code: 'sna',
      lang_subcode: null,
      readable_name: 'Shona',
      native_name: 'chiShona',
      lang_direction: 'ltr',
    },
  ],
  [
    'tum',
    {
      id: 'tum',
      lang_code: 'tum',
      lang_subcode: null,
      readable_name: 'Tumbuka',
      native_name: 'chiTumbuka',
      lang_direction: 'ltr',
    },
  ],
  [
    'co',
    {
      id: 'co',
      lang_code: 'co',
      lang_subcode: null,
      readable_name: 'Corsican',
      native_name: 'corsu, lingua corsa',
      lang_direction: 'ltr',
    },
  ],
  [
    'et',
    {
      id: 'et',
      lang_code: 'et',
      lang_subcode: null,
      readable_name: 'Estonian',
      native_name: 'eesti, eesti keel',
      lang_direction: 'ltr',
    },
  ],
  [
    'eu',
    {
      id: 'eu',
      lang_code: 'eu',
      lang_subcode: null,
      readable_name: 'Basque',
      native_name: 'euskara, euskera',
      lang_direction: 'ltr',
    },
  ],
  [
    'to',
    {
      id: 'to',
      lang_code: 'to',
      lang_subcode: null,
      readable_name: 'Tonga (Tonga Islands)',
      native_name: 'faka Tonga',
      lang_direction: 'ltr',
    },
  ],
  [
    'mlg',
    {
      id: 'mlg',
      lang_code: 'mlg',
      lang_subcode: null,
      readable_name: 'Malagasy',
      native_name: 'fiteny malagasy',
      lang_direction: 'ltr',
    },
  ],
  [
    'fo',
    {
      id: 'fo',
      lang_code: 'fo',
      lang_subcode: null,
      readable_name: 'Faroese',
      native_name: 'f\u00f8royskt',
      lang_direction: 'ltr',
    },
  ],
  [
    'sm',
    {
      id: 'sm',
      lang_code: 'sm',
      lang_subcode: null,
      readable_name: 'Samoan',
      native_name: 'gagana faa Samoa',
      lang_direction: 'ltr',
    },
  ],
  [
    'hr',
    {
      id: 'hr',
      lang_code: 'hr',
      lang_subcode: null,
      readable_name: 'Croatian',
      native_name: 'hrvatski',
      lang_direction: 'ltr',
    },
  ],
  [
    'nd',
    {
      id: 'nd',
      lang_code: 'nd',
      lang_subcode: null,
      readable_name: 'North Ndebele',
      native_name: 'isiNdebele',
      lang_direction: 'ltr',
    },
  ],
  [
    'nr',
    {
      id: 'nr',
      lang_code: 'nr',
      lang_subcode: null,
      readable_name: 'South Ndebele',
      native_name: 'isiNdebele',
      lang_direction: 'ltr',
    },
  ],
  [
    'xh',
    {
      id: 'xh',
      lang_code: 'xh',
      lang_subcode: null,
      readable_name: 'Xhosa',
      native_name: 'isiXhosa',
      lang_direction: 'ltr',
    },
  ],
  [
    'xho',
    {
      id: 'xho',
      lang_code: 'xho',
      lang_subcode: null,
      readable_name: 'Xhosa',
      native_name: 'isiXhosa',
      lang_direction: 'ltr',
    },
  ],
  [
    'zul',
    {
      id: 'zul',
      lang_code: 'zul',
      lang_subcode: null,
      readable_name: 'Zulu',
      native_name: 'isiZulu',
      lang_direction: 'ltr',
    },
  ],
  [
    'kl',
    {
      id: 'kl',
      lang_code: 'kl',
      lang_subcode: null,
      readable_name: 'Kalaallisut, Greenlandic',
      native_name: 'kalaallisut, kalaallit oqaasii',
      lang_direction: 'ltr',
    },
  ],
  [
    'rn',
    {
      id: 'rn',
      lang_code: 'rn',
      lang_subcode: null,
      readable_name: 'Kirundi',
      native_name: 'kiRundi',
      lang_direction: 'ltr',
    },
  ],
  [
    'la',
    {
      id: 'la',
      lang_code: 'la',
      lang_subcode: null,
      readable_name: 'Latin',
      native_name: 'latine, lingua latina',
      lang_direction: 'ltr',
    },
  ],
  [
    'lv',
    {
      id: 'lv',
      lang_code: 'lv',
      lang_subcode: null,
      readable_name: 'Latvian',
      native_name: 'latvie\u0161u valoda',
      lang_direction: 'ltr',
    },
  ],
  [
    'lt',
    {
      id: 'lt',
      lang_code: 'lt',
      lang_subcode: null,
      readable_name: 'Lithuanian',
      native_name: 'lietuvi\u0173 kalba',
      lang_direction: 'ltr',
    },
  ],
  [
    'ro',
    {
      id: 'ro',
      lang_code: 'ro',
      lang_subcode: null,
      readable_name: 'Romanian; Moldavian; Moldovan',
      native_name: 'rom\u00e2n\u0103',
      lang_direction: 'ltr',
    },
  ],
  [
    'rm',
    {
      id: 'rm',
      lang_code: 'rm',
      lang_subcode: null,
      readable_name: 'Romansh',
      native_name: 'rumantsch grischun',
      lang_direction: 'ltr',
    },
  ],
  [
    'sc',
    {
      id: 'sc',
      lang_code: 'sc',
      lang_subcode: null,
      readable_name: 'Sardinian',
      native_name: 'sardu',
      lang_direction: 'ltr',
    },
  ],
  [
    'sk',
    {
      id: 'sk',
      lang_code: 'sk',
      lang_subcode: null,
      readable_name: 'Slovak',
      native_name: 'sloven\u010dina',
      lang_direction: 'ltr',
    },
  ],
  [
    'sl',
    {
      id: 'sl',
      lang_code: 'sl',
      lang_subcode: null,
      readable_name: 'Slovene',
      native_name: 'sloven\u0161\u010dina',
      lang_direction: 'ltr',
    },
  ],
  [
    'fi',
    {
      id: 'fi',
      lang_code: 'fi',
      lang_subcode: null,
      readable_name: 'Finnish',
      native_name: 'suomi, suomen kieli',
      lang_direction: 'ltr',
    },
  ],
  [
    'sv',
    {
      id: 'sv',
      lang_code: 'sv',
      lang_subcode: null,
      readable_name: 'Swedish',
      native_name: 'svenska',
      lang_direction: 'ltr',
    },
  ],
  [
    'mi',
    {
      id: 'mi',
      lang_code: 'mi',
      lang_subcode: null,
      readable_name: 'M\u0101ori',
      native_name: 'te reo M\u0101ori',
      lang_direction: 'ltr',
    },
  ],
  [
    'fj',
    {
      id: 'fj',
      lang_code: 'fj',
      lang_subcode: null,
      readable_name: 'Fijian',
      native_name: 'vosa Vakaviti',
      lang_direction: 'ltr',
    },
  ],
  [
    'sg',
    {
      id: 'sg',
      lang_code: 'sg',
      lang_subcode: null,
      readable_name: 'Sango',
      native_name: 'y\u00e2ng\u00e2 t\u00ee s\u00e4ng\u00f6',
      lang_direction: 'ltr',
    },
  ],
  [
    'uz',
    {
      id: 'uz',
      lang_code: 'uz',
      lang_subcode: null,
      readable_name: 'Uzbek',
      native_name:
        'zbek, \u040e\u0437\u0431\u0435\u043a, \u0623\u06c7\u0632\u0628\u06d0\u0643\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'is',
    {
      id: 'is',
      lang_code: 'is',
      lang_subcode: null,
      readable_name: 'Icelandic',
      native_name: '\u00cdslenska',
      lang_direction: 'ltr',
    },
  ],
  [
    'cs',
    {
      id: 'cs',
      lang_code: 'cs',
      lang_subcode: null,
      readable_name: 'Czech',
      native_name: '\u010desky, \u010de\u0161tina',
      lang_direction: 'ltr',
    },
  ],
  [
    'el',
    {
      id: 'el',
      lang_code: 'el',
      lang_subcode: null,
      readable_name: 'Greek, Modern',
      native_name: '\u0395\u03bb\u03bb\u03b7\u03bd\u03b9\u03ba\u03ac',
      lang_direction: 'ltr',
    },
  ],
  [
    'be',
    {
      id: 'be',
      lang_code: 'be',
      lang_subcode: null,
      readable_name: 'Belarusian',
      native_name: '\u0411\u0435\u043b\u0430\u0440\u0443\u0441\u043a\u0430\u044f',
      lang_direction: 'ltr',
    },
  ],
  [
    'av',
    {
      id: 'av',
      lang_code: 'av',
      lang_subcode: null,
      readable_name: 'Avaric',
      native_name:
        '\u0430\u0432\u0430\u0440 \u043c\u0430\u0446\u04c0, \u043c\u0430\u0433\u04c0\u0430\u0440\u0443\u043b \u043c\u0430\u0446\u04c0',
      lang_direction: 'ltr',
    },
  ],
  [
    'ab',
    {
      id: 'ab',
      lang_code: 'ab',
      lang_subcode: null,
      readable_name: 'Abkhaz',
      native_name: '\u0430\u04a7\u0441\u0443\u0430',
      lang_direction: 'ltr',
    },
  ],
  [
    'ba',
    {
      id: 'ba',
      lang_code: 'ba',
      lang_subcode: null,
      readable_name: 'Bashkir',
      native_name: '\u0431\u0430\u0448\u04a1\u043e\u0440\u0442 \u0442\u0435\u043b\u0435',
      lang_direction: 'ltr',
    },
  ],
  [
    'bg',
    {
      id: 'bg',
      lang_code: 'bg',
      lang_subcode: null,
      readable_name: 'Bulgarian',
      native_name:
        '\u0431\u044a\u043b\u0433\u0430\u0440\u0441\u043a\u0438 \u0435\u0437\u0438\u043a',
      lang_direction: 'ltr',
    },
  ],
  [
    'os',
    {
      id: 'os',
      lang_code: 'os',
      lang_subcode: null,
      readable_name: 'Ossetian; Ossetic',
      native_name: '\u0438\u0440\u043e\u043d \u00e6\u0432\u0437\u0430\u0433',
      lang_direction: 'ltr',
    },
  ],
  [
    'kv',
    {
      id: 'kv',
      lang_code: 'kv',
      lang_subcode: null,
      readable_name: 'Komi',
      native_name: '\u043a\u043e\u043c\u0438 \u043a\u044b\u0432',
      lang_direction: 'ltr',
    },
  ],
  [
    'ky',
    {
      id: 'ky',
      lang_code: 'ky',
      lang_subcode: null,
      readable_name: 'Kirghiz; Kyrgyz',
      native_name: '\u043a\u044b\u0440\u0433\u044b\u0437 \u0442\u0438\u043b\u0438',
      lang_direction: 'ltr',
    },
  ],
  [
    'mk',
    {
      id: 'mk',
      lang_code: 'mk',
      lang_subcode: null,
      readable_name: 'Macedonian',
      native_name:
        '\u043c\u0430\u043a\u0435\u0434\u043e\u043d\u0441\u043a\u0438 \u0458\u0430\u0437\u0438\u043a',
      lang_direction: 'ltr',
    },
  ],
  [
    'mn',
    {
      id: 'mn',
      lang_code: 'mn',
      lang_subcode: null,
      readable_name: 'Mongolian',
      native_name: '\u043c\u043e\u043d\u0433\u043e\u043b',
      lang_direction: 'ltr',
    },
  ],
  [
    'ce',
    {
      id: 'ce',
      lang_code: 'ce',
      lang_subcode: null,
      readable_name: 'Chechen',
      native_name: '\u043d\u043e\u0445\u0447\u0438\u0439\u043d \u043c\u043e\u0442\u0442',
      lang_direction: 'ltr',
    },
  ],
  [
    'rue',
    {
      id: 'rue',
      lang_code: 'rue',
      lang_subcode: null,
      readable_name: 'Rusyn',
      native_name:
        '\u0440\u0443\u0441\u0438\u043d\u044c\u0441\u043a\u044b\u0439 \u044f\u0437\u044b\u043a',
      lang_direction: 'ltr',
    },
  ],
  [
    'ru',
    {
      id: 'ru',
      lang_code: 'ru',
      lang_subcode: null,
      readable_name: 'Russian',
      native_name: '\u0440\u0443\u0441\u0441\u043a\u0438\u0439 \u044f\u0437\u044b\u043a',
      lang_direction: 'ltr',
    },
  ],
  [
    'sr',
    {
      id: 'sr',
      lang_code: 'sr',
      lang_subcode: null,
      readable_name: 'Serbian',
      native_name: '\u0441\u0440\u043f\u0441\u043a\u0438 \u0458\u0435\u0437\u0438\u043a',
      lang_direction: 'ltr',
    },
  ],
  [
    'sr-CS',
    {
      id: 'sr-CS',
      lang_code: 'sr',
      lang_subcode: 'CS',
      readable_name: 'Serbian, Cyrillic',
      native_name: '\u0441\u0440\u043f\u0441\u043a\u0438 \u0458\u0435\u0437\u0438\u043a',
      lang_direction: 'ltr',
    },
  ],
  [
    'sr-LATN',
    {
      id: 'sr-LATN',
      lang_code: 'sr',
      lang_subcode: 'LATN',
      readable_name: 'Serbian, Latin',
      native_name: '\u0441\u0440\u043f\u0441\u043a\u0438 \u0458\u0435\u0437\u0438\u043a',
      lang_direction: 'ltr',
    },
  ],
  [
    'tt',
    {
      id: 'tt',
      lang_code: 'tt',
      lang_subcode: null,
      readable_name: 'Tatar',
      native_name:
        '\u0442\u0430\u0442\u0430\u0440\u0447\u0430, tatar\u00e7a, \u062a\u0627\u062a\u0627\u0631\u0686\u0627\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'tg',
    {
      id: 'tg',
      lang_code: 'tg',
      lang_subcode: null,
      readable_name: 'Tajik',
      native_name:
        '\u0442\u043e\u04b7\u0438\u043a\u04e3, to\u011fik\u012b, \u062a\u0627\u062c\u06cc\u06a9\u06cc\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'uk',
    {
      id: 'uk',
      lang_code: 'uk',
      lang_subcode: null,
      readable_name: 'Ukrainian',
      native_name: '\u0443\u043a\u0440\u0430\u0457\u043d\u0441\u044c\u043a\u0430',
      lang_direction: 'ltr',
    },
  ],
  [
    'cv',
    {
      id: 'cv',
      lang_code: 'cv',
      lang_subcode: null,
      readable_name: 'Chuvash',
      native_name: '\u0447\u04d1\u0432\u0430\u0448 \u0447\u04d7\u043b\u0445\u0438',
      lang_direction: 'ltr',
    },
  ],
  [
    'cu',
    {
      id: 'cu',
      lang_code: 'cu',
      lang_subcode: null,
      readable_name:
        'Old Church Slavonic; Church Slavic; Church Slavonic; Old Bulgarian; Old Slavonic',
      native_name:
        '\u0469\u0437\u044b\u043a\u044a \u0441\u043b\u043e\u0432\u0463\u043d\u044c\u0441\u043a\u044a',
      lang_direction: 'ltr',
    },
  ],
  [
    'kk',
    {
      id: 'kk',
      lang_code: 'kk',
      lang_subcode: null,
      readable_name: 'Kazakh',
      native_name: '\u049a\u0430\u0437\u0430\u049b \u0442\u0456\u043b\u0456',
      lang_direction: 'ltr',
    },
  ],
  [
    'hy',
    {
      id: 'hy',
      lang_code: 'hy',
      lang_subcode: null,
      readable_name: 'Armenian',
      native_name: '\u0540\u0561\u0575\u0565\u0580\u0565\u0576',
      lang_direction: 'ltr',
    },
  ],
  [
    'yi',
    {
      id: 'yi',
      lang_code: 'yi',
      lang_subcode: null,
      readable_name: 'Yiddish',
      native_name: '\u05d9\u05d9\u05b4\u05d3\u05d9\u05e9',
      lang_direction: 'rtl',
    },
  ],
  [
    'he',
    {
      id: 'he',
      lang_code: 'he',
      lang_subcode: null,
      readable_name: 'Hebrew (modern)',
      native_name: '\u05e2\u05d1\u05e8\u05d9\u05ea',
      lang_direction: 'rtl',
    },
  ],
  [
    'ur',
    {
      id: 'ur',
      lang_code: 'ur',
      lang_subcode: null,
      readable_name: 'Urdu',
      native_name: '\u0627\u0631\u062f\u0648',
      lang_direction: 'rtl',
    },
  ],
  [
    'ar',
    {
      id: 'ar',
      lang_code: 'ar',
      lang_subcode: null,
      readable_name: 'Arabic',
      native_name: '\u0627\u0644\u0639\u0631\u0628\u064a\u0629',
      lang_direction: 'rtl',
    },
  ],
  [
    'arq',
    {
      id: 'arq',
      lang_code: 'arq',
      lang_subcode: null,
      readable_name: 'Algerian; Darja',
      native_name: '\u062f\u0632\u064a\u0631\u064a\u0629',
      lang_direction: 'rtl',
    },
  ],
  [
    'fa',
    {
      id: 'fa',
      lang_code: 'fa',
      lang_subcode: null,
      readable_name: 'Persian',
      native_name: '\u0641\u0627\u0631\u0633\u06cc',
      lang_direction: 'rtl',
    },
  ],
  [
    'ps',
    {
      id: 'ps',
      lang_code: 'ps',
      lang_subcode: null,
      readable_name: 'Pashto; Pushto',
      native_name: '\u067e\u069a\u062a\u0648',
      lang_direction: 'rtl',
    },
  ],
  [
    'dv',
    {
      id: 'dv',
      lang_code: 'dv',
      lang_subcode: null,
      readable_name: 'Divehi; Dhivehi; Maldivian',
      native_name: '\u078b\u07a8\u0788\u07ac\u0780\u07a8',
      lang_direction: 'rtl',
    },
  ],
  [
    'awa',
    {
      id: 'awa',
      lang_code: 'awa',
      lang_subcode: null,
      readable_name: 'Awadhi',
      native_name: '\u0905\u0935\u0927\u0940',
      lang_direction: 'ltr',
    },
  ],
  [
    'ks',
    {
      id: 'ks',
      lang_code: 'ks',
      lang_subcode: null,
      readable_name: 'Kashmiri',
      native_name:
        '\u0915\u0936\u094d\u092e\u0940\u0930\u0940, \u0643\u0634\u0645\u064a\u0631\u064a\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'mai',
    {
      id: 'mai',
      lang_code: 'mai',
      lang_subcode: null,
      readable_name: 'Maithili',
      native_name: '\u0925\u093f\u0932\u0940, \u09ae\u09c8\u09a5\u09bf\u09b2\u09c0',
      lang_direction: 'ltr',
    },
  ],
  [
    'new',
    {
      id: 'new',
      lang_code: 'new',
      lang_subcode: null,
      readable_name: 'Newari',
      native_name:
        '\u0928\u0947\u092a\u093e\u0932 \u092d\u093e\u0937\u093e, \u0928\u0947\u0935\u093e\u0903 \u092d\u093e\u092f\u094d',
      lang_direction: 'ltr',
    },
  ],
  [
    'ne',
    {
      id: 'ne',
      lang_code: 'ne',
      lang_subcode: null,
      readable_name: 'Nepali',
      native_name: '\u0928\u0947\u092a\u093e\u0932\u0940',
      lang_direction: 'ltr',
    },
  ],
  [
    'ne-NP',
    {
      id: 'ne-NP',
      lang_code: 'ne',
      lang_subcode: 'NP',
      readable_name: 'Nepali',
      native_name: '\u0928\u0947\u092a\u093e\u0932\u0940',
      lang_direction: 'ltr',
    },
  ],
  [
    'nsp',
    {
      id: 'nsp',
      lang_code: 'nsp',
      lang_subcode: null,
      readable_name: 'Nepalese Sign Language',
      native_name:
        '\u0928\u0947\u092a\u093e\u0932\u0940 \u0938\u093e\u0902\u0915\u0947\u0924\u093f\u0915 \u092d\u093e\u0937\u093e',
      lang_direction: 'ltr',
    },
  ],
  [
    'pi',
    {
      id: 'pi',
      lang_code: 'pi',
      lang_subcode: null,
      readable_name: 'P\u0101li',
      native_name: '\u092a\u093e\u0934\u093f',
      lang_direction: 'ltr',
    },
  ],
  [
    'bh',
    {
      id: 'bh',
      lang_code: 'bh',
      lang_subcode: null,
      readable_name: 'Bihari',
      native_name: '\u092d\u094b\u091c\u092a\u0941\u0930\u0940',
      lang_direction: 'ltr',
    },
  ],
  [
    'bho',
    {
      id: 'bho',
      lang_code: 'bho',
      lang_subcode: null,
      readable_name: 'Bhojpuri; Bajpuri; Bhojapuri',
      native_name: '\u092d\u094b\u091c\u092a\u0941\u0930\u0940',
      lang_direction: 'ltr',
    },
  ],
  [
    'mr',
    {
      id: 'mr',
      lang_code: 'mr',
      lang_subcode: null,
      readable_name: 'Marathi (Mar\u0101\u1e6dh\u012b)',
      native_name: '\u092e\u0930\u093e\u0920\u0940',
      lang_direction: 'ltr',
    },
  ],
  [
    'mwr',
    {
      id: 'mwr',
      lang_code: 'mwr',
      lang_subcode: null,
      readable_name: 'Marwari; Marwadi',
      native_name:
        '\u092e\u093e\u0930\u0935\u093e\u0921\u093c\u0940, \u0645\u0627\u0631\u0648\u0627\u0691\u06cc',
      lang_direction: 'ltr',
    },
  ],
  [
    'sa',
    {
      id: 'sa',
      lang_code: 'sa',
      lang_subcode: null,
      readable_name: 'Sanskrit (Sa\u1e41sk\u1e5bta)',
      native_name: '\u0938\u0902\u0938\u094d\u0915\u0943\u0924\u092e\u094d',
      lang_direction: 'ltr',
    },
  ],
  [
    'sd',
    {
      id: 'sd',
      lang_code: 'sd',
      lang_subcode: null,
      readable_name: 'Sindhi',
      native_name:
        '\u0938\u093f\u0928\u094d\u0927\u0940, \u0633\u0646\u068c\u064a\u060c \u0633\u0646\u062f\u06be\u06cc\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'hi',
    {
      id: 'hi',
      lang_code: 'hi',
      lang_subcode: null,
      readable_name: 'Hindi',
      native_name: '\u0939\u093f\u0928\u094d\u0926\u0940, \u0939\u093f\u0902\u0926\u0940',
      lang_direction: 'ltr',
    },
  ],
  [
    'as',
    {
      id: 'as',
      lang_code: 'as',
      lang_subcode: null,
      readable_name: 'Assamese',
      native_name: '\u0985\u09b8\u09ae\u09c0\u09af\u09bc\u09be',
      lang_direction: 'ltr',
    },
  ],
  [
    'bn',
    {
      id: 'bn',
      lang_code: 'bn',
      lang_subcode: null,
      readable_name: 'Bengali',
      native_name: '\u09ac\u09be\u0982\u09b2\u09be',
      lang_direction: 'ltr',
    },
  ],
  [
    'pnb',
    {
      id: 'pnb',
      lang_code: 'pnb',
      lang_subcode: null,
      readable_name: 'Punjabi',
      native_name: '\u0a2a\u0a70\u0a1c\u0a3e\u0a2c\u0a40',
      lang_direction: 'ltr',
    },
  ],
  [
    'pa',
    {
      id: 'pa',
      lang_code: 'pa',
      lang_subcode: null,
      readable_name: 'Panjabi; Punjabi',
      native_name:
        '\u0a2a\u0a70\u0a1c\u0a3e\u0a2c\u0a40, \u067e\u0646\u062c\u0627\u0628\u06cc\u200e',
      lang_direction: 'ltr',
    },
  ],
  [
    'gu',
    {
      id: 'gu',
      lang_code: 'gu',
      lang_subcode: null,
      readable_name: 'Gujarati',
      native_name: '\u0a97\u0ac1\u0a9c\u0ab0\u0abe\u0aa4\u0ac0',
      lang_direction: 'ltr',
    },
  ],
  [
    'or',
    {
      id: 'or',
      lang_code: 'or',
      lang_subcode: null,
      readable_name: 'Oriya',
      native_name: '\u0b13\u0b21\u0b3c\u0b3f\u0b06',
      lang_direction: 'ltr',
    },
  ],
  [
    'ta',
    {
      id: 'ta',
      lang_code: 'ta',
      lang_subcode: null,
      readable_name: 'Tamil',
      native_name: '\u0ba4\u0bae\u0bbf\u0bb4\u0bcd',
      lang_direction: 'ltr',
    },
  ],
  [
    'te',
    {
      id: 'te',
      lang_code: 'te',
      lang_subcode: null,
      readable_name: 'Telugu',
      native_name: '\u0c24\u0c46\u0c32\u0c41\u0c17\u0c41',
      lang_direction: 'ltr',
    },
  ],
  [
    'kn',
    {
      id: 'kn',
      lang_code: 'kn',
      lang_subcode: null,
      readable_name: 'Kannada',
      native_name: '\u0c95\u0ca8\u0ccd\u0ca8\u0ca1',
      lang_direction: 'ltr',
    },
  ],
  [
    'ml',
    {
      id: 'ml',
      lang_code: 'ml',
      lang_subcode: null,
      readable_name: 'Malayalam',
      native_name: '\u0d2e\u0d32\u0d2f\u0d3e\u0d33\u0d02',
      lang_direction: 'ltr',
    },
  ],
  [
    'si',
    {
      id: 'si',
      lang_code: 'si',
      lang_subcode: null,
      readable_name: 'Sinhala; Sinhalese',
      native_name: '\u0dc3\u0dd2\u0d82\u0dc4\u0dbd',
      lang_direction: 'ltr',
    },
  ],
  [
    'th',
    {
      id: 'th',
      lang_code: 'th',
      lang_subcode: null,
      readable_name: 'Thai',
      native_name: '\u0e44\u0e17\u0e22',
      lang_direction: 'ltr',
    },
  ],
  [
    'lo',
    {
      id: 'lo',
      lang_code: 'lo',
      lang_subcode: null,
      readable_name: 'Lao',
      native_name: '\u0e9e\u0eb2\u0eaa\u0eb2\u0ea5\u0eb2\u0ea7',
      lang_direction: 'ltr',
    },
  ],
  [
    'bo',
    {
      id: 'bo',
      lang_code: 'bo',
      lang_subcode: null,
      readable_name: 'Tibetan Standard; Tibetan, Central',
      native_name: '\u0f56\u0f7c\u0f51\u0f0b\u0f61\u0f72\u0f42',
      lang_direction: 'ltr',
    },
  ],
  [
    'my',
    {
      id: 'my',
      lang_code: 'my',
      lang_subcode: null,
      readable_name: 'Burmese',
      native_name: '\u1017\u1019\u102c\u1005\u102c',
      lang_direction: 'ltr',
    },
  ],
  [
    'ka',
    {
      id: 'ka',
      lang_code: 'ka',
      lang_subcode: null,
      readable_name: 'Georgian',
      native_name: '\u10e5\u10d0\u10e0\u10d7\u10e3\u10da\u10d8',
      lang_direction: 'ltr',
    },
  ],
  [
    'ti',
    {
      id: 'ti',
      lang_code: 'ti',
      lang_subcode: null,
      readable_name: 'Tigrinya',
      native_name: '\u1275\u130d\u122d\u129b',
      lang_direction: 'ltr',
    },
  ],
  [
    'tir',
    {
      id: 'tir',
      lang_code: 'tir',
      lang_subcode: null,
      readable_name: 'Tigrinya',
      native_name: '\u1275\u130d\u122d\u129b',
      lang_direction: 'ltr',
    },
  ],
  [
    'am',
    {
      id: 'am',
      lang_code: 'am',
      lang_subcode: null,
      readable_name: 'Amharic',
      native_name: '\u12a0\u121b\u122d\u129b',
      lang_direction: 'ltr',
    },
  ],
  [
    'iu',
    {
      id: 'iu',
      lang_code: 'iu',
      lang_subcode: null,
      readable_name: 'Inuktitut',
      native_name: '\u1403\u14c4\u1483\u144e\u1450\u1466',
      lang_direction: 'ltr',
    },
  ],
  [
    'oj',
    {
      id: 'oj',
      lang_code: 'oj',
      lang_subcode: null,
      readable_name: 'Ojibwe; Ojibwa',
      native_name: '\u140a\u14c2\u1511\u14c8\u142f\u14a7\u140e\u14d0',
      lang_direction: 'ltr',
    },
  ],
  [
    'oji',
    {
      id: 'oji',
      lang_code: 'oji',
      lang_subcode: null,
      readable_name: 'Ojibwe',
      native_name: '\u140a\u14c2\u1511\u14c8\u142f\u14a7\u140e\u14d0',
      lang_direction: 'ltr',
    },
  ],
  [
    'cr',
    {
      id: 'cr',
      lang_code: 'cr',
      lang_subcode: null,
      readable_name: 'Cree',
      native_name: '\u14c0\u1426\u1403\u152d\u140d\u140f\u1423',
      lang_direction: 'ltr',
    },
  ],
  [
    'km',
    {
      id: 'km',
      lang_code: 'km',
      lang_subcode: null,
      readable_name: 'Khmer',
      native_name: '\u1797\u17b6\u179f\u17b6\u1781\u17d2\u1798\u17c2\u179a',
      lang_direction: 'ltr',
    },
  ],
  [
    'zh-CN',
    {
      id: 'zh-CN',
      lang_code: 'zh',
      lang_subcode: 'CN',
      readable_name: 'Chinese, Simplified',
      native_name: '\u4e2d\u56fd\u5927\u9646',
      lang_direction: 'ltr',
    },
  ],
  [
    'zh',
    {
      id: 'zh',
      lang_code: 'zh',
      lang_subcode: null,
      readable_name: 'Chinese',
      native_name: '\u4e2d\u6587, \u6c49\u8bed, \u6f22\u8a9e',
      lang_direction: 'ltr',
    },
  ],
  [
    'ja',
    {
      id: 'ja',
      lang_code: 'ja',
      lang_subcode: null,
      readable_name: 'Japanese',
      native_name:
        '\u65e5\u672c\u8a9e (\u306b\u307b\u3093\u3054\uff0f\u306b\u3063\u307d\u3093\u3054)',
      lang_direction: 'ltr',
    },
  ],
  [
    'zh-Hant',
    {
      id: 'zh-Hant',
      lang_code: 'zh',
      lang_subcode: 'Hant',
      readable_name: 'Chinese, Traditional',
      native_name: '\u6f22\u8a9e (\u7e41\u9ad4\u5b57)',
      lang_direction: 'ltr',
    },
  ],
  [
    'zh-TW',
    {
      id: 'zh-TW',
      lang_code: 'zh',
      lang_subcode: 'TW',
      readable_name: 'Chinese, Taiwan',
      native_name: '\u6f22\u8a9e (\u81fa\u7063)',
      lang_direction: 'ltr',
    },
  ],
  [
    'ii',
    {
      id: 'ii',
      lang_code: 'ii',
      lang_subcode: null,
      readable_name: 'Nuosu',
      native_name: '\ua188\ua320\ua4bf Nuosuhxop',
      lang_direction: 'ltr',
    },
  ],
  [
    'ko',
    {
      id: 'ko',
      lang_code: 'ko',
      lang_subcode: null,
      readable_name: 'Korean',
      native_name:
        '\ud55c\uad6d\uc5b4 (\u97d3\u570b\u8a9e), \uc870\uc120\ub9d0 (\u671d\u9bae\u8a9e)',
      lang_direction: 'ltr',
    },
  ],
]);

export default LanguagesMap;

export const LanguagesList = Array.from(LanguagesMap.values());

export const LanguagesNames = {
  ACH: 'ach',
  OM: 'om',
  AA: 'aa',
  AF: 'af',
  AK: 'ak',
  AKA: 'aka',
  RSN: 'rsn',
  AN: 'an',
  EN_PT: 'en-PT',
  IG: 'ig',
  IBO: 'ibo',
  GN: 'gn',
  ID: 'id',
  SU: 'su',
  BI: 'bi',
  BRH: 'brh',
  EN_GB: 'en-GB',
  BUG: 'bug',
  BXK: 'bxk',
  CSX: 'csx',
  CA: 'ca',
  CEB: 'ceb',
  CH: 'ch',
  CHO: 'cho',
  CY: 'cy',
  THL: 'thl',
  DA: 'da',
  SE: 'se',
  DE: 'de',
  NV: 'nv',
  DTY: 'dty',
  TAJ: 'taj',
  EFI: 'efi',
  NA: 'na',
  GUZ: 'guz',
  ENQ: 'enq',
  EN: 'en',
  ES: 'es',
  ES_AR: 'es-AR',
  ES_ES: 'es-ES',
  ES_MX: 'es-MX',
  ES_NI: 'es-NI',
  EO: 'eo',
  EE: 'ee',
  FR_CA: 'fr-CA',
  FR: 'fr',
  FY: 'fy',
  FY_NL: 'fy-NL',
  FUL: 'ful',
  FUV: 'fuv',
  FF: 'ff',
  GA: 'ga',
  GV: 'gv',
  GL: 'gl',
  GD: 'gd',
  KI: 'ki',
  KIK: 'kik',
  HDY: 'hdy',
  HA: 'ha',
  HAU: 'hau',
  HO: 'ho',
  HRX: 'hrx',
  IO: 'io',
  RW: 'rw',
  IA: 'ia',
  IT: 'it',
  IK: 'ik',
  MH: 'mh',
  KAK: 'kak',
  KR: 'kr',
  KW: 'kw',
  KG: 'kg',
  KON: 'kon',
  KAM: 'kam',
  SW: 'sw',
  SWA: 'swa',
  HT: 'ht',
  KJ: 'kj',
  KU: 'ku',
  LKT: 'lkt',
  BMA: 'bma',
  LI: 'li',
  LN: 'ln',
  LIN: 'lin',
  LUA: 'lua',
  LU: 'lu',
  LG: 'lg',
  MYX: 'myx',
  XOG: 'xog',
  LB: 'lb',
  HU: 'hu',
  MG: 'mg',
  MT: 'mt',
  MUL: 'mul',
  NL: 'nl',
  TUV: 'tuv',
  NO: 'no',
  NB: 'nb',
  NN: 'nn',
  PBU: 'pbu',
  OC: 'oc',
  LWG: 'lwg',
  IE: 'ie',
  HZ: 'hz',
  NG: 'ng',
  PMS: 'pms',
  FIL: 'fil',
  PL: 'pl',
  PT: 'pt',
  PT_BR: 'pt-BR',
  PT_PT: 'pt-PT',
  TY: 'ty',
  QUE: 'que',
  QU: 'qu',
  NYN: 'nyn',
  ZA: 'za',
  SCO: 'sco',
  SRR: 'srr',
  NSO: 'nso',
  ST: 'st',
  SOT: 'sot',
  TN: 'tn',
  TSN: 'tsn',
  SQ: 'sq',
  SS: 'ss',
  SID: 'sid',
  SOM: 'som',
  SO: 'so',
  BCC: 'bcc',
  PBT: 'pbt',
  SV_SE: 'sv-SE',
  SV_FI: 'sv-FI',
  XKI: 'xki',
  BE_TARA: 'be-tara',
  VI: 'vi',
  TPI: 'tpi',
  VE: 've',
  TW: 'tw',
  TK: 'tk',
  TR: 'tr',
  UND: 'und',
  UR_PK: 'ur-PK',
  UG: 'ug',
  VO: 'vo',
  WA: 'wa',
  TL: 'tl',
  WAL: 'wal',
  WO: 'wo',
  WOL: 'wol',
  TS: 'ts',
  YAO: 'yao',
  YO: 'yo',
  YOR: 'yor',
  AE: 'ae',
  AY: 'ay',
  AZ: 'az',
  MS: 'ms',
  BM: 'bm',
  JV: 'jv',
  BS: 'bs',
  BR: 'br',
  NYA: 'nya',
  NY: 'ny',
  SN: 'sn',
  SNA: 'sna',
  TUM: 'tum',
  CO: 'co',
  ET: 'et',
  EU: 'eu',
  TO: 'to',
  MLG: 'mlg',
  FO: 'fo',
  SM: 'sm',
  HR: 'hr',
  ND: 'nd',
  NR: 'nr',
  XH: 'xh',
  XHO: 'xho',
  ZUL: 'zul',
  KL: 'kl',
  RN: 'rn',
  LA: 'la',
  LV: 'lv',
  LT: 'lt',
  RO: 'ro',
  RM: 'rm',
  SC: 'sc',
  SK: 'sk',
  SL: 'sl',
  FI: 'fi',
  SV: 'sv',
  MI: 'mi',
  FJ: 'fj',
  SG: 'sg',
  UZ: 'uz',
  IS: 'is',
  CS: 'cs',
  EL: 'el',
  BE: 'be',
  AV: 'av',
  AB: 'ab',
  BA: 'ba',
  BG: 'bg',
  OS: 'os',
  KV: 'kv',
  KY: 'ky',
  MK: 'mk',
  MN: 'mn',
  CE: 'ce',
  RUE: 'rue',
  RU: 'ru',
  SR: 'sr',
  SR_CS: 'sr-CS',
  SR_LATN: 'sr-LATN',
  TT: 'tt',
  TG: 'tg',
  UK: 'uk',
  CV: 'cv',
  CU: 'cu',
  KK: 'kk',
  HY: 'hy',
  YI: 'yi',
  HE: 'he',
  UR: 'ur',
  AR: 'ar',
  ARQ: 'arq',
  FA: 'fa',
  PS: 'ps',
  DV: 'dv',
  AWA: 'awa',
  KS: 'ks',
  MAI: 'mai',
  NEW: 'new',
  NE: 'ne',
  NE_NP: 'ne-NP',
  NSP: 'nsp',
  PI: 'pi',
  BH: 'bh',
  BHO: 'bho',
  MR: 'mr',
  MWR: 'mwr',
  SA: 'sa',
  SD: 'sd',
  HI: 'hi',
  AS: 'as',
  BN: 'bn',
  PNB: 'pnb',
  PA: 'pa',
  GU: 'gu',
  OR: 'or',
  TA: 'ta',
  TE: 'te',
  KN: 'kn',
  ML: 'ml',
  SI: 'si',
  TH: 'th',
  LO: 'lo',
  BO: 'bo',
  MY: 'my',
  KA: 'ka',
  TI: 'ti',
  TIR: 'tir',
  AM: 'am',
  IU: 'iu',
  OJ: 'oj',
  OJI: 'oji',
  CR: 'cr',
  KM: 'km',
  ZH_CN: 'zh-CN',
  ZH: 'zh',
  JA: 'ja',
  ZH_HANT: 'zh-Hant',
  ZH_TW: 'zh-TW',
  II: 'ii',
  KO: 'ko',
};
