
<template>

  <svg viewBox="0 0 16 16">
    <circle cx="8" cy="10" r="3" />
  </svg>

</template>
<script>

  export default {
    name: 'IndicatorIcon',
  };

</script>
<style scoped>
	svg {
		fill: currentColor
	}
</style>
