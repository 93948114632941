var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "KButtonGroup",
        { staticClass: "dots", staticStyle: { "margin-top": "8px" } },
        [
          _c("KIconButton", {
            staticClass: "globe",
            attrs: { icon: "language", "aria-hidden": "true", tabindex: "-1" },
            on: {
              click: function ($event) {
                _vm.showLanguageModal = true
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "selected",
              style: { margin: "0" },
              attrs: { title: _vm.selectedLanguage.english_name },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.selectedLanguage.lang_name) + "\n    "
              ),
            ]
          ),
          _vm._l(_vm.buttonLanguages, function (language) {
            return _c("KButton", {
              key: language.id,
              staticClass: "lang",
              style: { margin: "0 2px" },
              attrs: {
                text: language.lang_name,
                title: language.english_name,
                appearance: "basic-link",
              },
              on: {
                click: function ($event) {
                  return _vm.switchLanguage(language.id)
                },
              },
            })
          }),
          _vm.numSelectableLanguages > _vm.numVisibleLanguages + 1
            ? _c("KButton", {
                attrs: {
                  text: _vm.$tr("showMoreLanguagesSelector"),
                  primary: false,
                  appearance: "flat-button",
                },
                on: {
                  click: function ($event) {
                    _vm.showLanguageModal = true
                  },
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.showLanguageModal
        ? _c("LanguageSwitcherModal", {
            staticClass: "ta-l",
            on: {
              cancel: function ($event) {
                _vm.showLanguageModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }