var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MessageLayout", {
    attrs: { header: _vm.$tr("accountDeletedTitle") },
    scopedSlots: _vm._u([
      {
        key: "back",
        fn: function () {
          return [
            _c(
              "VBtn",
              { attrs: { color: "primary", to: { name: "Main" }, large: "" } },
              [_vm._v("\n      " + _vm._s(_vm.$tr("backToLogin")) + "\n    ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }