var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KCheckbox",
    {
      attrs: {
        value: _vm.value,
        label: _vm.label,
        showLabel: _vm.showLabel,
        indeterminate: _vm.indeterminate,
        disabled: _vm.disabled,
        description: _vm.description,
        checked: _vm.isChecked,
        labelDir: _vm.labelDir,
      },
      on: { change: _vm.handleChange },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }