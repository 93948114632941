var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VApp",
    [
      _c(
        "VLayout",
        {
          staticClass: "main pt-5",
          attrs: { "fill-height": "", "justify-center": "" },
        },
        [
          _c(
            "div",
            [
              _c(
                "VCard",
                {
                  staticClass: "pa-4",
                  staticStyle: { width: "300px", margin: "0 auto" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "k-logo-container" },
                    [
                      _c("KLogo", {
                        attrs: {
                          altText: "Kolibri Logo with background",
                          showBackground: true,
                          size: 120,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "h2",
                    { staticClass: "primary--text py-2 text-xs-center" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tr("kolibriStudio")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("Banner", {
                    attrs: {
                      value: _vm.loginFailed,
                      text: _vm.$tr("loginFailed"),
                      error: "",
                    },
                  }),
                  _c("Banner", {
                    attrs: {
                      value: _vm.offline,
                      text: _vm.$tr("loginFailedOffline"),
                      error: "",
                    },
                  }),
                  _c("Banner", {
                    staticClass: "pb-0 px-0",
                    attrs: {
                      value: Boolean(_vm.nextParam),
                      text: _vm.$tr("loginToProceed"),
                      "data-test": "loginToProceed",
                    },
                  }),
                  _c(
                    "VForm",
                    {
                      ref: "form",
                      staticClass: "py-3",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("EmailField", {
                        attrs: { autofocus: "" },
                        model: {
                          value: _vm.username,
                          callback: function ($$v) {
                            _vm.username = $$v
                          },
                          expression: "username",
                        },
                      }),
                      _c("PasswordField", {
                        attrs: { label: _vm.$tr("passwordLabel") },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                      _c(
                        "p",
                        [
                          _c("ActionLink", {
                            attrs: {
                              to: { name: "ForgotPassword" },
                              text: _vm.$tr("forgotPasswordLink"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c("KButton", {
                        staticClass: "w-100",
                        attrs: {
                          primary: "",
                          text: _vm.$tr("signInButton"),
                          disabled: _vm.offline || _vm.busy,
                          type: "submit",
                        },
                      }),
                      _c("KRouterLink", {
                        staticClass: "mt-2 w-100",
                        attrs: {
                          primary: "",
                          text: _vm.$tr("createAccountButton"),
                          to: { name: "Create" },
                          appearance: "flat-button",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("VDivider"),
                  _c(
                    "p",
                    { staticClass: "mt-4 text-xs-center" },
                    [
                      _c("ActionLink", {
                        attrs: {
                          href: "/channels",
                          text: _vm.$tr("guestModeLink"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("LanguageSwitcherList", {
                staticClass: "mt-3 text-xs-center",
              }),
              _c("p", { staticClass: "links mt-3 text-xs-center" }, [
                _c(
                  "span",
                  [
                    _c("ActionLink", {
                      attrs: { text: _vm.$tr("privacyPolicyLink") },
                      on: { click: _vm.showPrivacyPolicy },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _c("ActionLink", {
                      attrs: { text: _vm.$tr("TOSLink") },
                      on: { click: _vm.showTermsOfService },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _c("ActionLink", {
                      attrs: {
                        text: _vm.$tr("copyright", {
                          year: new Date().getFullYear(),
                        }),
                        href: "https://learningequality.org/",
                        target: "_blank",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("PolicyModals"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }