var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VApp",
    { staticStyle: { overflow: "auto", height: "100vh" } },
    [
      _c(
        "VLayout",
        {
          staticClass: "mt-5 pt-5",
          attrs: { "justify-center": "", "fill-height": "" },
        },
        [
          _c("VFlex", { staticStyle: { "max-width": "900px" } }, [
            _c(
              "h1",
              { staticClass: "font-weight-bold headline text-xs-center" },
              [_vm._v("\n        " + _vm._s(_vm.header) + "\n      ")]
            ),
            _c("p", { staticClass: "mb-5 mt-1 subheading text-xs-center" }, [
              _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "max-width": "400px",
                  margin: "0 auto",
                  "text-align": "center",
                },
              },
              [
                _vm._t("default"),
                _c(
                  "p",
                  { staticClass: "mt-4" },
                  [
                    _vm._t("back", function () {
                      return [
                        _c("KRouterLink", {
                          attrs: {
                            to: { name: "Main" },
                            text: _vm.$tr("backToLogin"),
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }