var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { attrs: { viewBox: "0 0 16 16" } }, [
    _c("circle", { attrs: { cx: "8", cy: "10", r: "3" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }