var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: { size: "large", title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.needsAcceptance
                ? [
                    _c(
                      "KFixedGrid",
                      { attrs: { numCols: 2 } },
                      [
                        _c(
                          "KGridItem",
                          { attrs: { layout: { span: 1 } } },
                          [
                            _c("KCheckbox", {
                              attrs: {
                                label: _vm.$tr("checkboxText"),
                                checked: _vm.policyAccepted,
                                "data-test": "accept-checkbox",
                              },
                              on: { change: _vm.togglePolicyAccepted },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "KGridItem",
                          { attrs: { layout: { span: 1 } } },
                          [
                            _c("KButton", {
                              style: {
                                display: "block",
                                "margin-left": "auto",
                              },
                              attrs: {
                                text: _vm.$tr("continueButton"),
                                primary: true,
                                disabled: !_vm.policyAccepted,
                                "data-test": "continue-button",
                              },
                              on: { click: _vm.onPolicyAccept },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _c("KButton", {
                    attrs: {
                      text: _vm.$tr("closeButton"),
                      "data-test": "close-button",
                    },
                    on: { click: _vm.onPolicyClose },
                  }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("p", { staticClass: "body-1 mt-2" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$tr("lastUpdated", { date: _vm.$formatDate(_vm.date) })
            ) +
            "\n  "
        ),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }