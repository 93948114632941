var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.component,
    _vm._b(
      {
        tag: "component",
        staticStyle: { position: "relative" },
        attrs: { id: _vm.id },
      },
      "component",
      _vm.$attrs,
      false
    ),
    [
      _vm._t("default", null, {
        attach: "#" + _vm.id,
        menuProps: _vm.menuProps,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }